import { forwardRef } from 'react';
import './photo.scss';

// export default function Photo(props) {
const Photo = forwardRef(({ project, handleNavigateToProjectDetail }, ref) => {
  // const { project, handleNavigateToProjectDetail } = props;

  return (
    <div
      ref={ref}
      className="photoContainer"
      onClick={() => handleNavigateToProjectDetail(project)}
    >
      <div className="imageContainer">
        <img src={project.profilImage} alt="product" loading="lazy" />
        <div>
          <p style={{ fontFamily: 'customFont, sans-serif' }}>
            {project.title}
          </p>
          <p style={{ fontFamily: 'customFont, sans-serif' }}>
            <span>{project.quotation}</span>
          </p>
          {project.detail}
        </div>
      </div>
      <div style={{ fontFamily: 'customFont, sans-serif' }}>
        {project.subCategory}
      </div>
    </div>
  );
});
export default Photo;
