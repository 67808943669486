export interface Data {
  projectId: number;
  category: Category;
  subCategory: string;
  title: string;
  label: string;
  subLabel?: string;
  images: string[];
  imageBis?: string[];
  profilImage: string;
  date: string;
  detail: string;
  quotation: string;
  coworker: string;
  url?: string;
}

export enum Category {
  ARCHITECTURE = 'ARCHITECTURE',
  STREET_ART = 'STREET ART',
  MODELS = 'MAQUETTES',
  OTHER = 'AUTRE',
}
