import { Category } from '../../types/dataType';
import './categoryHeader.scss';

export default function CategoryHeader(props) {
  const { handleSelectedCategory, selectedCategory } = props;

  return (
    <div className="d-flex justify-content-center">
      <div className="selectionFilter">
        {Object.keys(Category).map((category) => (
          <div
            className={
              selectedCategory === Category[category]
                ? 'cardStyleSelected'
                : 'card'
            }
            onClick={() => handleSelectedCategory(Category[category])}
          >
            {Category[category]}
          </div>
        ))}
      </div>
    </div>
  );
}
