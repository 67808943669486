// eslint-disable
import { Category, Data } from '../types/dataType';

const projects: Data[] = [
  {
    projectId: 11,
    category: Category.STREET_ART,
    subCategory: 'Street Art',
    title: 'Posca sur toile',
    label: 'Posca sur toile',
    images: [
      '/images/carres/1_Street_art/graff0.jpg',
      '/images/carres/1_Street_art/graff2.jpg',
      '/images/carres/1_Street_art/graff3.jpg',
      '/images/carres/1_Street_art/graff4.jpg',
      '/images/carres/1_Street_art/graff5.jpg',
    ],
    profilImage: '/images/carres/1_Street_art/graff4.jpg',
    date: 'en 2024',
    detail: '',
    quotation: '« Un aller retour entre formes et couleurs »',
    coworker: '',
  },
  {
    projectId: 12,
    category: Category.OTHER,
    subCategory: 'T-shirt',
    title: 'T-shirt',
    label: 'T-shirt',
    images: [
      '/images/carres/8_Tshirt/tshirt0.jpg',
      '/images/carres/8_Tshirt/tshirt1.jpg',
      '/images/carres/8_Tshirt/tshirt2.jpg',
      '/images/carres/8_Tshirt/tshirt3.jpg',
      '/images/carres/8_Tshirt/tshirt4.jpg',
      '/images/carres/8_Tshirt/tshirt5.jpg',
    ],
    profilImage: '/images/carres/8_Tshirt/tshirt0.jpg',
    date: '',
    detail: '',
    quotation: "« Vers l'entreprenariat... »",
    coworker: '',
  },
  {
    projectId: 7,
    category: Category.ARCHITECTURE,
    subCategory: 'architecture',
    title: 'PFE : Révéler et Intégrer',
    label: 'PFE : Révéler et Intégrer',
    subLabel: 'La partie territoriale a été réalisée en groupe ',
    images: [
      '/images/carres/7_Projet_M2/6_image.jpg',
      '/images/carres/7_Projet_M2/7_image.jpg',
      '/images/carres/7_Projet_M2/8_image.jpg',
      '/images/carres/7_Projet_M2/9_image.jpg',
      '/images/carres/7_Projet_M2/00_affichage.jpg',
      '/images/carres/7_Projet_M2/0_plan_masse.jpg',
      '/images/carres/7_Projet_M2/1_plan_masse_projet.jpg',
      '/images/carres/7_Projet_M2/2_plan.jpg',
      '/images/carres/7_Projet_M2/3_coupe.jpg',
      '/images/carres/7_Projet_M2/4_coupe_gd.jpg',
      '/images/carres/7_Projet_M2/5_axo.jpg',
    ],
    imageBis: [
      '/images/carres/7_Projet_M2/carte_faille.jpg',
      '/images/carres/7_Projet_M2/carte_sentier.jpg',
    ],
    profilImage: '/images/carres/7_Projet_M2/7_image.jpg',
    date: 'en Master 2',
    detail:
      'Notre site de projet du PFE se situe dans les environs de Braga (Portugal).\n\
    Une faille géologique parcourt le bassin versant du Cavado c’est pourquoi nous la qualifions de figure de l’invisible. Il faut l’arpenter, la sillonner en surface pour qu’elle révèle de nombreux paysages. Le paysage n’est donc pas qu’une question surfacique car il dépend d’une épaisseur allant du visible à l’invisible, c’est-à-dire de l’atmosphère à la lithosphère. Nous cherchons alors à rendre cette figure palpable par la création d’un sentier. L’enjeu à la grande échelle est de donner une épaisseur par la marche à ces paysages fascinants face à l’uniformisation du territoire.\n\
    Parmi ces paysages fascinants, voici mon site de projet : au sud de Braga, le sommet d’un mont abrite des traces d’un autre temps avec la présence d’énormes roches, appelées chaos, résultantes d’une érosion très longue mais aussi la présence de nombreuses ruines des civilisations celte et romaine. Cependant ces traces subissent de nombreux altérations naturelles et surtout humaines qui effacent leurs richesses géologiques et archéologiques.  \n\
    En intégrant des masses préexistantes (naturelles et anthropiques) au processus de construction, le projet consiste en la création de deux espaces muséographiques à l’air libre, autour du thème archéologique pour l’un, géologique pour l’autre et d’une antenne technique. Ces deux programmes ont pour but de reconquérir le sommet mais de manière positive pour révéler et rendre au lieu ses symboliques originelles. \n\
    Situé en contrebas du sommet, le projet fait office de visite intellectuelle du site pour ensuite pouvoir partir à sa découverte et son arpentage. Je vois donc ce projet comme une permanence face à l’impermanence des crises ; tant que le projet existe la pratique du site se révèle mais les dimensions technique et touristique ici attribuées viennent déranger a minima le sommet et ses richesses archéologiques et géologiques. \n\
    ',
    coworker: '',
    quotation: '« Sublimer la minéralité et l’histoire »',
  },
  {
    projectId: 13,
    category: Category.MODELS,
    subCategory: 'Maquettes',
    title: 'Maquettes',
    label: 'Maquettes',
    images: [
      '/images/carres/9_Maquettes/maquette_M1_1.jpg',
      '/images/carres/9_Maquettes/maquette_M1_2.jpg',
      '/images/carres/9_Maquettes/maquette_M1_3.jpg',
      '/images/carres/9_Maquettes/maquette_M1_4.jpg',
      '/images/carres/9_Maquettes/maquette_PFE_1.jpg',
      '/images/carres/9_Maquettes/maquette_PFE_2.jpg',
      '/images/carres/9_Maquettes/maquette_PFE_3.jpg',
      '/images/carres/9_Maquettes/maquette_PFE_4.jpg',
      '/images/carres/9_Maquettes/maquette_PFE_5.jpg',
      '/images/carres/9_Maquettes/maquette_PFE_6.jpg',
      '/images/carres/9_Maquettes/maquette_PFE_7.jpg',
      '/images/carres/9_Maquettes/maquette_PFE_8.jpg',
      '/images/carres/9_Maquettes/maquette_site_1.jpg',
      '/images/carres/9_Maquettes/maquette_site_2.jpg',
    ],
    profilImage: '/images/carres/9_Maquettes/maquette_PFE_2.jpg',
    date: '',
    detail: '',
    quotation: '« Un chantier miniature »',
    coworker: '',
  },
  {
    projectId: 6,
    category: Category.OTHER,
    subCategory: 'ecrit',
    title: 'Mémoire',
    label: 'Mémoire',
    images: [],
    profilImage: '/images/carres/6_Memoire/parge_garde_mémoire8V.jpg',
    url: 'https://online.fliphtml5.com/jvplg/ivoe/',
    date: 'en Master 2',
    detail: '',
    coworker: '',
    quotation: '',
  },
  {
    projectId: 5,
    category: Category.ARCHITECTURE,
    subCategory: 'architecture',
    title: 'Gare d’Ambert',
    label: 'Réactivation de la gare d’Ambert',
    subLabel: 'La partie territoriale a été réalisée en groupe ',
    images: [
      '/images/carres/5_Projet_M1/00_mep.jpg',
      '/images/carres/5_Projet_M1/1_axo_site.jpg',
      '/images/carres/5_Projet_M1/2_plan_masse.jpg',
      '/images/carres/5_Projet_M1/3_gd_elevation.jpg',
      '/images/carres/5_Projet_M1/4_detail_gare.jpg',
      '/images/carres/5_Projet_M1/5_detail_gare.jpg',
      '/images/carres/5_Projet_M1/6_detail_services.jpg',
      '/images/carres/5_Projet_M1/7_zoom_train.jpg',
      '/images/carres/5_Projet_M1/8_zoom_services.jpg',
    ],
    imageBis: ['/images/carres/5_Projet_M1/0_carte_commun.jpg'],
    profilImage: '/images/carres/5_Projet_M1/7_zoom_train.jpg',
    date: 'en Master 1',
    detail:
      'Comme l’entend le domaine d’étude du master EVAN, la dimension territoriale se veut intégrer aux processus de projet d’où une problématique rencontrée par la métropole clermontoise, « Comment la nourrir ? », traitée en groupe. Notre objectif visait à conduire cette réflexion jusqu’à une autosuffisance alimentaire entre Clermont-Ferrand et son grand territoire qu’est le Livradois Forez. \
      Chaque étudiant traitait donc un maillon de ce nouveau mode de consommation abordé autant au niveau de la production que du transport ou de la vente des denrées alimentaires.\n\
    Mon travail était alors centré autour la partie transport, l’objectif étant de réactiver la gare d’Ambert ainsi que la voie ferrée du Livradois-Forez partiellement fermée aujourd’hui. J’ai ainsi choisi de traiter toute la frange d’entrée de ville comme un grand parvis de gare qui serait ponctué par différentes « gares » au rôle propre :\n\
    -	Gare des voyageurs. Il s’agit de la gare actuelle mais fermée bordant un parc avec plan d’eau où la voie ferrée crée une limite entre ces espaces puis la ville même. Le projet se fixe ici au niveau de l’espace public afin de créer un lien entre ce parc et la ville et pour tendre à une homogénéité entre les différents pans : phases d’intervention.\n\
    -	Gare des trains. Ajouter une extension un bâtiment existant qui sert actuellement de stockage des vélo rails se révèle nécessaire afin de créer un atelier de maintenance/réparation SNCF. Cette extension pensée dans la continuité du bâti existant reprend les deux éléments que sont la brique et la taille des ouvertures du bâtiment existant. \n\
    -	Gare des services. Il est imaginé la circulation d’un nouveau type de train dédié aux services sur cette voie ferrée. A son bord, par exemple, un café, une pharmacie, un marché de producteurs locaux, il stationnerait sur le quai cette gare spécifique. Mais ce quai et cette gare gardent d’autres fonctions polyvalentes.  \n\
    En fait, j’ai travaillé ces différents bâtiments comme une seule entité reprenant les codes architecturaux du bâtiment existant de la gare des trains et ayant pour but commun de mettre en avant la voie ferrée. \
    ',
    coworker: '',
    quotation: '« Des réflexions à toutes les échelles »',
  },
  {
    projectId: 14,
    category: Category.OTHER,
    subCategory: 'Dessins',
    title: 'Dessins',
    label: 'Dessins',
    images: [
      '/images/carres/10_Dessins_Vals/Parcours_Vals_2.png',
      '/images/carres/10_Dessins_Vals/Parcours_Vals_1.jpg',
    ],
    profilImage: '/images/carres/10_Dessins_Vals/Parcours_Vals_2.png',
    date: '',
    detail: '',
    quotation: "« Support graphique pour le rapport d'étude »",
    coworker: '',
  },
  {
    projectId: 4,
    category: Category.OTHER,
    subCategory: 'ecrit',
    title: "Rapport d'étude",
    label: "Rapport d'étude",
    images: [],
    profilImage: '/images/carres/4_Rapport_detude/parge_garde_rapport_v.jpg',
    url: 'https://online.fliphtml5.com/jvplg/opbb/',
    date: 'en Master 1',
    detail: '',
    coworker: '',
    quotation: '',
  },
  {
    projectId: 3,
    category: Category.ARCHITECTURE,
    subCategory: 'architecture',
    title: '60 Logements',
    label: '60 Logements',
    images: [
      '/images/carres/3_Projet_L3.2/0_axo.jpg',
      '/images/carres/3_Projet_L3.2/1_axo_zoom.jpg',
      '/images/carres/3_Projet_L3.2/3_elevation.jpg',
    ],
    profilImage: '/images/carres/3_Projet_L3.2/3_elevation81.jpg',
    date: 'en licence 3',
    detail:
      "Situé à l'est du plateau des Cézaux, le projet réalisé reprend une forme récurrente sur ce site :\
    la barre. Cette barre réadaptée est composée de 60 logements et d'équipements\
    publics. Les logements sont organisés autour d'une coursive extérieure rectiligne\
    allant d'une extrémité à l'autre du projet.\
    Autour de cette dernière s'accrochent de part et d'autre des blocs de logements variés.\
    La coursive se termine au sud en fusionnant avec l'entrée du bloc des logements étudiants.\n\
    Puisque ces blocs ne sont pas accolés, des logements traversants avec double ou triple orientation\
    prennent forme mais également des circulations verticales à l'extérieur où les habitants\
    voient défiler le paysage lorsqu'ils les empruntent. Enfin une certaine porosité ou aération se dégage de la barre.\n\
    Ce projet oscille entre l'échelle urbaine et l'échelle du projet, entre le travail de groupe et le travail personnel.\
    Une approche entraperçue pendant mon stage L2 et confirmée par le stage L3.\
    De plus, «faire du logement» relève d'une certaine routine où les espaces sont normés qui\
    libère du temps alors consacré à l'architecture globale.",
    quotation: '« Utiliser tous mes acquis »',
    coworker: '',
  },
  {
    projectId: 10,
    category: Category.ARCHITECTURE,
    subCategory: 'architecture',
    title: 'Complexe funéraire',
    label: 'Complexe funéraire',
    images: [
      '/images/carres/2_Projet_L2.2/image_06.jpg',
      '/images/carres/2_Projet_L2.2/image_07.jpg',
      '/images/carres/2_Projet_L2.2/image_1.jpg',
      '/images/carres/2_Projet_L2.2/image_2.jpg',
      '/images/carres/2_Projet_L2.2/image_3.jpg',
      '/images/carres/2_Projet_L2.2/image_4.jpg',
    ],
    profilImage: '/images/carres/2_Projet_L2.2/image_1.jpg',
    date: 'en licence 2',
    detail:
      "Dans le programme du cimetière, nous avons choisi de développer le lien Terre/Ciel.  Au seuil de l'entrée, quatre cubes se profilent à notre gauche qui seront les columbariums, tel des blocs sortant de terre avec, logées dans leurs murs, les urnes. Et à notre droite, un long mur qui a pour but d'isoler le programme du cimetière de celui du centre équestre. Ce mur et les columbariums vont s'enfoncer dans la terre en formant une perspective avec comme point final la salle de recueillement.\n\
      La réflexion de ce projet s'est faite uniquement par la maquette. La question de la matérialité devait alors être plus poussée, aussi fabriquer des maquettes en béton plutôt qu'en carton m'a tout de suite exhalté. Le résultat est immédiatement plus sensible, on a envie de s'approcher, de toucher la matière. Cependant, contrairement au carton facile à travailler, le béton n'est pas maléable à souhait et des étapes sont nécessaires à sa mise en oeuvre : faire un moule, préparer et couler le béton, attendre et enfin démouler.\n\
      Plusieurs tests concluants nous amènent à une maquette au 1/50ème d'une partie de notre projet. Et là, plusieurs surprises : le permier moule casse, le second partiellement ; préparer 60kg de béton à la main, c'est long ; des traces du moule restent incustées dans le béton sec ; finalement avec le socle c'est une maquette de 80 kg à transporter ! Beaucoup d'expérimentations, de péripéties, de temps mais j'en garde de très bons souvenirs et des moments de rire.",
    quotation: '« Une surprise cachée derriere chaque matériau »',
    coworker: 'réalisé en binôme',
  },
  {
    projectId: 15,
    category: Category.OTHER,
    subCategory: 'Dessins',
    title: 'Dessins',
    label: 'Dessins',
    images: [
      '/images/carres/11_Dessin_projet/1.png',
      '/images/carres/11_Dessin_projet/2.png',
      '/images/carres/11_Dessin_projet/3.png',
      '/images/carres/11_Dessin_projet/4.png',
      '/images/carres/11_Dessin_projet/5.png',
      '/images/carres/11_Dessin_projet/6.png',
    ],
    profilImage: '/images/carres/11_Dessin_projet/2.png',
    date: '',
    detail: '',
    quotation: '« Story-board »',
    coworker: '',
  },
  {
    projectId: 2,
    category: Category.ARCHITECTURE,
    subCategory: 'architecture',
    title: 'Maillage de parcs',
    label: 'Maillage de parcs',
    images: [
      '/images/carres/3_Projet_L3/0_plan.jpg',
      '/images/carres/3_Projet_L3/0_schema_directeur.png',
      '/images/carres/3_Projet_L3/1_parcs.jpg',
    ],
    profilImage: '/images/carres/3_Projet_L3/1_parcs_1.jpg',
    date: 'en licence 3',
    detail:
      "L'objectif de ce projet est de relier le tissu urbain des alentours de la fontaine du Bac aux différents espaces dits «parcs» qui apporteront de nouveaux programmes.\n\
    En s'appuyant sur les espaces verts déja présents mais non qualifiés, notre projet donne naissance à plusieurs parcs urbains, l'un est tantôt végétal, tantôt minéral, l'autre laissé libre, parfois construit... Mais un seul objectif est poursuivi : créer une trame verte qui se propage au-delà des limites du site\n\
    Une nouvelle approche, à savoir regarder la ville, comment elle fonctionne, pourquoi une route est tracée ici, depuis quand tel ou tel élément existe là, résulte de ce projet. C'est une façon de réfléchir à l'opposé du projet architectural où on est focalisé sur une parcelle que j'ai perçue. ",
    quotation: '« Travailler le tout »',
    coworker: 'Carolina Caros - Louise Leblanc - Lauren Sauser - Pol Jouany',
  },
  {
    projectId: 1,
    category: Category.ARCHITECTURE,
    subCategory: 'architecture',
    title: 'Logement',
    label: 'Logement',
    images: [
      '/images/carres/2_Projet_L2/00_mep.jpg',
      '/images/carres/2_Projet_L2/0_iso.jpg',
      '/images/carres/2_Projet_L2/1_plan.jpg',
      '/images/carres/2_Projet_L2/2_elevation.jpg',
    ],
    profilImage: '/images/carres/2_Projet_L2/2_elevation_1.jpg',
    date: 'en licence 2',
    detail:
      "Situé en contrebas du puy de Chanturgue, ce projet s'intègre dans une proposition collective travaillant sur la frontière ville/nature. L'objectif est de répartir chacun des projets le long d'un sentier en bas de Chanturgue afin de limiter l'urbanisation qui, petit à petit, gagne ces terres.\n\
      Les maisons Michelin étant construites à proximité, mon projet se veut à l'échelle du site. Le logement collectif est alors traité à plus petite échelle en réunissant T2, T3 en duplex, T4, ce qui me donne naissance à 4 modules composés de ces 3 logements, mais agencés différamement.\n\
      Une ligne de béton apporte par ses plis et replis des atouts aussi bien aux’éléments privés que publics. En se faufilant entre les logements, ce serpent crée plusieurs types d'espace : les cages d'escalier s'installent dans le retournement d'un mur, la continuité d'une dalle forme un porche d'entrée, des terrasse ou des murs de soutènement par exemple.\n\
      A l'échelle du projet, le tout se voit comme une unité homogène composée de plusieurs «maisons individuelles accolées».\n\
      Trouver la bonne dimension pour chaque élément est la dimension chronophage à souligner. Il en est de même du positionnement de la ligne de béton qui toujours répond aux questions : pourquoi, qu'apporte-t-elle, pour quoi ? Aucun élément ne se répète, il s'agit penser à tout, sans cesse.",
    quotation: '« Un casse-tête agréable... » ',
    coworker: '',
  },
  {
    projectId: 0,
    category: Category.ARCHITECTURE,
    subCategory: 'architecture',
    title: 'Le Cube',
    label: 'Le Cube',
    images: ['/images/carres/0_Projet_L1/1_psd.jpg'],
    profilImage: '/images/carres/0_Projet_L1/parge_garde_cube.jpg',
    date: 'en licence 1',
    detail:
      "Là est mon premier projet. Il s'agit d'effectuer diverses transformations dans un cube qui donneront lieu à une architecture. Que faire ? Percer une face ? Faire tourner ce cube ? Le réduire ou l'agrandir ? Le dupliquer ? Aucune idée... mais la certitude qui jaillit dans mon esprit est de mettre en place une architecture dans le jardin de l'école qui attirerait la curiosité et donnerait envie d'y entrer afin de voir ce que l'intérieur propose.\n\
    Je découvre alors la force que peut dégager une maquette. Faire le projet par la maquette, c'est se rendre compte directement des formes, des espaces, des jeux de lumières, de la volumétrie globale, du site. Je prends un morceau de carton, j'y découpe des formes sans trop savoir où cela mène, je me rends compe qu'elles sont parfois trop homogènes, parfois trop petites, parfois trop «belles». Un long processus d'essai s'engage.\n\
    Finalement ce projet raconte l'histoire du crash du cube, qui en s'écrasant aurait vu des morceaux de ces faces se détacher et se perdre alentour. Les ouvertures ainsi créées sont destinées au passage et à l'apport de lummière tandis que les morceaux épars servent de mobilier urbain à l'intérieur. ",
    coworker: '',
    quotation: '« Apprendre à penser l’Architecture »',
  },
];

export { projects };
