import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { projects } from '../../utils/data';
import CategoryHeader from '../categoryHeader/categoryHeader';
import Photo from '../photo/photo';
import styles from './categoryGrid.module.scss';

export default function CategoryGrid() {
  const [selectedCategory, setSelectedCategory] = useState('');
  const [displayProject, setDisplayProject] = useState(projects);

  const elementsRef = useRef([]);
  const navigate = useNavigate();

  const handleNavigateToProjectDetail = (project) => {
    navigate(`/project/${project.projectId}`);
  };

  const handleSelectedCategory = (category) => {
    setSelectedCategory(selectedCategory === category ? '' : category);
  };

  useEffect(() => {
    setDisplayProject(
      projects.filter((project) => project.category.includes(selectedCategory))
    );
  }, [selectedCategory]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-fade-in');
            // observer.unobserve(entry.target); // Optionnal: stop this obersvation after intersecting
          } else {
            entry.target.classList.remove('animate-fade-in');
          }
        });
      },
      {
        threshold: 0.2,
      }
    );

    // using a copy for the clean up function
    const currentElementsRef = elementsRef.current;
    currentElementsRef.forEach((el) => {
      if (el) {
        observer.observe(el);
      }
    });

    return () => {
      currentElementsRef.forEach((el) => {
        if (el) {
          observer.unobserve(el);
        }
      });
    };
  }, []);

  return (
    <div id="categoryGrid" className={`${styles.container}`}>
      <CategoryHeader
        handleSelectedCategory={handleSelectedCategory}
        isInHeader={false}
        selectedCategory={selectedCategory}
      />
      <div className={styles.gridProject}>
        {displayProject.length > 0 ? (
          displayProject.map((project, index) => (
            <Photo
              key={index}
              ref={(el) => (elementsRef.current[index] = el)}
              project={project}
              handleNavigateToProjectDetail={handleNavigateToProjectDetail}
            />
          ))
        ) : (
          <div className="w-100 mt-5 d-flex justify-content-center">
            <div>Aucun Projet</div>
          </div>
        )}
      </div>
    </div>
  );
}
