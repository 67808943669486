import { useParams } from 'react-router-dom';
import { projects } from '../../utils/data';
import Header from '../header/header';
import './projectDetail.scss';

import { useEffect, useState } from 'react';
import SwiperCore, {
  A11y,
  Navigation,
  Pagination,
  Scrollbar,
  Zoom,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css/thumbs';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import 'swiper/scss/zoom';
import { Data } from '../../types/dataType';

SwiperCore.use([Navigation, Pagination, Scrollbar, Zoom]);
export default function ProjectDetail() {
  const { projectId } = useParams<string>();
  const [project, setProject] = useState<Data>();
  const [showGallery, setShowGallery] = useState<boolean>(false);

  // const [imgsLoaded, setImgsLoaded] = useState(false);

  // const loadImage = (image: string): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     const loadImg = new Image();
  //     loadImg.src = image;
  //     // wait 2 seconds to simulate loading time
  //     loadImg.onload = () =>
  //       setTimeout(() => {
  //         resolve(image);
  //       }, 2000);

  //     loadImg.onerror = (err) => reject(err);
  //   });
  // };

  useEffect(() => {
    if (projectId) {
      setProject(
        projects.find((project) => project.projectId === parseInt(projectId))
      );

      // //preloading image
      // project?.images.forEach((image) => {
      //   new Image().src = image;
      // });
      // const loadImages = async () => {
      //   const images = project?.images ?? [];
      //   try {
      //     for (const image of images) {
      //       await loadImage(image);
      //     }
      //     setImgsLoaded(true);
      //   } catch (err) {
      //     console.log('Failed to load images', err);
      //   }
      // };

      // loadImages();
    }
  }, [projectId, project]);

  // if (!imgsLoaded) {
  //   return <div>img loading</div>;
  // }

  //display a pdf reader for the pdf project
  if (project?.subCategory.toLowerCase() === 'ecrit') {
    return (
      <div className="projectContainer">
        <Header />
        <div className="flipBook">
          <div className="" style={{ overflow: 'hidden' }}>
            <iframe
              title={project.title}
              style={{
                width: '1000px',
                height: '800px',
                marginTop: '-50px',
              }}
              src={project.url}
              seamless={true}
              scrolling="no"
              frameBorder="0"
              // allowtransparency={'true'.toString()}
              allowFullScreen={true}
            ></iframe>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="projectContainer">
      {showGallery ? (
        <Swiper
          className="carroussel"
          modules={[Navigation, Pagination, Scrollbar, Zoom, A11y]}
          spaceBetween={0}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          loop
          // params={{
          //   zoom: {
          //     enabled: true,
          //   },
          // }}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
          onClick={() => setShowGallery(false)}
        >
          {project?.images.map((image, i) => (
            <SwiperSlide zoom key={i}>
              <img src={image} key={i} alt="product" />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="contentContainer">
          <div
            className="titleContainer"
            style={{ fontFamily: 'customFont, sans-serif' }}
          >
            <span>{project?.label}</span>
            <br />
            {project?.quotation}
            <br />
            <br />
            {project?.date}
            <br />
            {project?.subLabel}
          </div>
          <div className="detailContainer">
            <div className="textContainer">
              {project?.detail?.split('\n').map((str, key) => (
                <p key={key}>{str}</p>
              ))}
            </div>
            <div className="imgContainer">
              <Swiper
                className="carroussel"
                modules={[Navigation, Pagination, Scrollbar, Zoom, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                loop
                // params={{
                //   zoom: {
                //     enabled: true,
                //   },
                // }}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                onClick={() => setShowGallery(true)}
              >
                {project?.images.map((image, i) => (
                  <SwiperSlide zoom key={i}>
                    <img loading="eager" src={image} key={i} alt="product" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>

        // <>
        //   {/* <Header /> */}
        //   <div className="d-flex flex-row">
        //     <div className="w-25">
        //       <div className={styles.informations}>
        //         <div className="text-center fw-bold">{project.label}</div>
        //         {project.subLabel && (
        //           <div className="text-center fs-7 mb-3">
        //             {project.subLabel}
        //           </div>
        //         )}
        //         {project.date && (
        //           <div className="text-center mb-3">soutenu {project.date}</div>
        //         )}
        //         {project.coworker && (
        //           <div className="text-center mb-3">
        //             réalisé en groupe avec {project.coworker}
        //           </div>
        //         )}

        //         {project.quotation && (
        //           <div className="text-center fw-bold fst-italic">
        //             {project.quotation}
        //           </div>
        //         )}
        //         <div className={styles.detail}>
        //           {project.detail.split('\n').map((str, key) => (
        //             <p key={key}>{str}</p>
        //           ))}
        //         </div>
        //       </div>
        //     </div>
        //     <div className="w-75 p-5 pointer d-flex flex-column align-items-center">
        //       {(project.label === 'PFE : Révéler et Intégrer' ||
        //         project.label === 'Réactivation de la gare d’Ambert') && (
        //         <div className={styles.selectionContainer}>
        //           <div
        //             className={
        //               selectedCategory === 'Stratégie territoriale'
        //                 ? styles.cardStyleSelected
        //                 : styles.card
        //             }
        //             onClick={() =>
        //               handleSelectedCategory('Stratégie territoriale')
        //             }
        //           >
        //             Stratégie territoriale
        //           </div>
        //           <div
        //             className={
        //               selectedCategory === 'Projet architecture'
        //                 ? styles.cardStyleSelected
        //                 : styles.card
        //             }
        //             onClick={() =>
        //               handleSelectedCategory('Projet architecture')
        //             }
        //           >
        //             Projet architecture
        //           </div>
        //         </div>
        //       )}
        //       <div onClick={() => setShowGallery(true)}>
        //         {selectedCategory === 'Projet architecture'
        //           ? project.images?.map((image, imageKey) => (
        //               <div className={styles.imageContainer}>
        //                 <img src={image} key={imageKey} alt="product" />
        //               </div>
        //             ))
        //           : project.imageBis?.map((image, imageKey) => (
        //               <div className={styles.imageContainer}>
        //                 <img src={image} key={imageKey} alt="product" />
        //               </div>
        //             ))}
        //       </div>
        /* <div onClick={() => setShowGallery(true)}>
                {project.images.map((image, imageKey) => (
                  <div className={styles.imageContainer}>
                    <img src={image} key={imageKey} alt="product" />
                  </div>
                ))}
              </div> */
        //   </div>
        // </div>
        // </>
      )}
    </div>
  );
}
