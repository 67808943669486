import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import CV from '../../utils/CV.pdf';
import PORTFOLIO from '../../utils/Portfolio.pdf';
import CategoryGrid from '../categoryGrid/categoryGrid';
import './main.scss';

export default function Main() {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = (route) => {
    navigate(route);
  };

  useEffect(() => {
    function handleScroll() {
      setIsScrolled(window.scrollY > 100);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="homePage">
        <div className="titles">
          <div className={`categoryHeader ${isScrolled && 'hidden'}`}>
            <button
              className="headerList"
              onClick={() => handleNavigate('/categoryGrid')}
              style={{ fontFamily: 'customFont, sans-serif' }}
            >
              <HashLink to="/#categoryGrid">PROJETS</HashLink>
            </button>
            <button
              className="headerList"
              onClick={() => handleNavigate('/about')}
              style={{ fontFamily: 'customFont, sans-serif' }}
            >
              A PROPOS
            </button>
            <button
              className="headerList"
              style={{ fontFamily: 'customFont, sans-serif' }}
            >
              <a href={CV} target="_blank" rel="noopener noreferrer">
                CV
              </a>
            </button>
            <button
              className="headerList"
              style={{ fontFamily: 'customFont, sans-serif' }}
            >
              <a href={PORTFOLIO} target="_blank" rel="noopener noreferrer">
                PORTFOLIO
              </a>
            </button>
          </div>
          <span style={{ fontFamily: 'customFont, sans-serif' }}>
            site personnel
          </span>
        </div>
        <section className="section10 demo">
          <HashLink to="/#categoryGrid">
            <span></span>
          </HashLink>
        </section>
      </div>
      <CategoryGrid />
    </>
  );
}
