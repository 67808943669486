import { Route, Routes } from 'react-router-dom';
import './app.scss';

import About from './components/about/about';
import Header from './components/header/header';
import Main from './components/main/main';
import ProjectDetail from './components/projectDetail/projectDetail';
function App() {
  return (
    <div className="app">
      <Header />
      <Routes>
        <Route path="*" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/project/:projectId" element={<ProjectDetail />} />
      </Routes>
    </div>
  );
}

export default App;
