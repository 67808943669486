import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import CV from '../../utils/CV.pdf';
import PORTFOLIO from '../../utils/Portfolio.pdf';
import './header.scss';

export default function Header(props) {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleAPropos = () => {
    navigate('/about');
  };

  const handleMain = () => {
    navigate('/');
  };

  useEffect(() => {
    console.log('location :>> ', location);
    function handleScroll() {
      if (location.pathname === '/' || location.pathname === '/categoryGrid') {
        setIsVisible(window.scrollY > 100);
      } else {
        setIsVisible(true);
      }
    }

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return (
    <div
      className={`header ${
        !isVisible && 'headerBackground'
      } d-flex justify-content-between`}
    >
      <div className={` d-flex flex-row `} role="button" onClick={handleMain}>
        {/* <div className={logo}>B·A··AA</div> */}
        <div className="title">SITE PERSONNEL | Amaury Bringuier</div>
      </div>
      <div className="d-flex flex-row flex-wrap">
        <div className={`headerList`} role="button">
          <HashLink to="/#categoryGrid">PROJETS</HashLink>
        </div>
        <div className={`headerList`} role="button" onClick={handleAPropos}>
          A PROPOS
        </div>
        <div className={`headerList`} role="button">
          <a href={CV} target="_blank" rel="noopener noreferrer">
            CV
          </a>
        </div>
        <div className={`headerList`} role="button">
          <a href={PORTFOLIO} target="_blank" rel="noopener noreferrer">
            PORTFOLIO
          </a>
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  handleSelectedCategory: PropTypes.func,
};

Header.defaultProps = {
  handleSelectedCategory: () => {},
};
