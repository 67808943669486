import './about.scss';

export default function About() {
  return (
    <div className="aboutContainer">
      <div className="category">
        <strong>Mon profil</strong>
        <br />
        <br />
        Mordu de Promarker et de sport parce que rien ne vaut un bon dessin pour
        se faire comprendre et un cerveau bien oxygéné pour être ouvert et
        créatif ! <br />
        <br />
        Des années de cours de dessin mais loin du tracé académique : Street
        Art. <br />
        <br />
        Jeux de construction, de société et lectures de styles fantastiques.
        Escalade, natation, vélo trial, ski alpin et plusieurs classes de neige,
        badminton. Un joli tour de France réalisé au fil des vacances scolaires
        et des séjours en Egypte, Canaries, Tunisie, Angleterre, Norvège,
        Belgique, Portugal ou dans des familles allemandes.
      </div>
      <div className="category">
        <strong>Ma vision de l’architecture</strong>
        <br />
        <br />
        Architecte. Voici un mot qui, du plus loin que je me souvienne me
        faisait vibrer et me projetait dans mon métier futur. Des constructions
        simplistes en Lego aux tracés à la Escher et maquettes, je me créais un
        univers générique de l’architecture, éliminant ce qui ne me plaisait pas
        (les grand ouvrages types ponts, projet public comme parc, places,
        gymnase, hôpital…). C’est donc avec la volonté de construire des
        maisons, des immeubles, des lieux de vie pour l’homme que j’entrai à
        l’ENSA de Clermont-Ferrand.
        <br />
        <br />
        L’idée stéréotypée d’un architecte-bâtisseur armé d’outils et de
        rouleaux de plans fut éclipsée au profit d’un architecte-penseur, d’un
        architecte-agenceur d’espaces. L’architecture est avant tout et surtout
        un travail de réflexion et ne peut se résumer à l’acte seul de « bâtir
        ». Ne pas construire pour construire mais pour répondre à un besoin.
        C’est pourquoi, je souhaite approfondir ce cheminement de projet qui ne
        peut se dessiner qu’en fonction du territoire dans lequel il s’implante
        et des enjeux contemporains que traversent notre pays et notre planète.
      </div>
      <div id="idTest" className="category">
        <strong>Mon master</strong>
        <br />
        <br />
        Le master EVAN (Entre Ville Architecture et Nature) développe une
        approche critique sur les mutations des territoires métropolitains
        induites par les multiples enjeux environnementaux, économiques et
        sociétaux qui obligent à penser la transition, la résilience, la
        transformation et la régénération des milieux habités. L‘entrelacement
        des échelles conduit à s’intéresser tout autant aux conditions de
        fabrication du territoire public (sols, climat, usages…) qu’à celles de
        la production architecturale (filières, matériaux et mode
        constructifs…). La démarche de projet invite à questionner le rôle de
        l’architecte, les modes de programmations (réversibilité et
        adaptabilité) ainsi que les outils d’arpentage, de conception et de
        représentation qu’il convient de renouveler.
      </div>
    </div>
  );
}
